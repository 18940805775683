import request from '@/utils/request'

export const doLogin = (data) => {
  return request({
    url: '/login/doLogin',
    method: 'post',
    data,
  })
}
export const complaintSave = (data) => {
  return request({
    url: '/complaint/save',
    method: 'post',
    data,
  })
}
